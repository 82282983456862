
import { mapState } from 'vuex'
import vClickOutside from 'v-click-outside'
import HeaderCommon from '../components/layout/HeaderCommon'
import ListMenu from '../components/layout/ListMenu'
import FooterCommon from '../components/layout/FooterCommon'
import FooterMobile from '../components/layout/FooterMobile'
import LoadingBar from '../components/LoadingBar'
import NotificationBlock from '../components/block-ui/NotificationBlock'
import ExpandContact from '../components/contact/ExpandContact.vue'
import {
  MASTER_GET_DATA,
  AUTH_OPEN_MODAL_LOGIN,
  INDEX_SET_HIDE_FOOTER,
  CHAT_SET_UN_READ_MESSAGE,
  CHAT_FETCH_ROOMS,
  GET_SETTING,
  INDEX_SET_SETTING,
  PHONE_CONTACT
} from '../store/store.const'
import { removeVietnameseTones, textToSlug } from '../utils/utils'
import ServiceCommon from '../components/home/ServiceCommon'
import RegisterCommon from '../components/home/RegisterCommon'
// import ChatBox from '../components/element-ui/Chat/ChatBox'

export default {
  name: 'DefaultLayout',
  components: {
    FooterMobile,
    LoadingBar,
    FooterCommon,
    HeaderCommon,
    ListMenu,
    NotificationBlock,
    ServiceCommon,
    RegisterCommon,
    ExpandContact
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  async asyncData({ store, redirect }) {
    try {
      const setting = await store.dispatch(GET_SETTING) // fetchDataSetting
      store.commit(INDEX_SET_SETTING, setting.data)
    } catch (e) {
      console.error(e)
      redirect(302, '/not_found')
    }
  },
  data() {
    return {
      categories: [
        {
          name: '',
          link: '',
          active: true,
          children: []
        }
      ],
      windowWidth: 0,
      timeAccess: {
        value: 0,
        expiry: 0
      },
      showPhoneAni: true,
      supportExpand: false,
      popupPhoneContact: false,
      phoneNumber: '',
      listImagePhoneContact: ['/assets/images/home/popup-phone-contact-02.jpg', '/assets/images/home/popup-phone-contact-02.jpg'],
      imagePhoneContact: '',
      ramdomImagePhoneContact: Math.floor(Math.random() * 2),
      phonePlaceHolder: 'SĐT ĐẶT HÀNG'
    }
  },
  async fetch() {
    await this.fetchDataCategory()
  },
  head() {
    const scripts = {}
    if (!process.browser) return scripts
    if (this.setting && this.setting.meta_pixel) {
      const parentFirst = document.createElement('div')
      parentFirst.innerHTML = this.setting.meta_pixel
      parentFirst.childNodes.forEach(child => {
        let tag = child.tagName
        if (tag === undefined) return
        tag = tag.toLowerCase()

        if (scripts[tag] === undefined) scripts[tag] = []
        scripts[tag].push({
          innerHTML: child.innerHTML
        })
      })
    }
    if (this.setting && this.setting.google_tag_head) {
      const parentSecond = document.createElement('div')
      parentSecond.innerHTML = this.setting.google_tag_head
      parentSecond.childNodes.forEach(child => {
        let tag = child.tagName
        if (tag === undefined) return
        tag = tag.toLowerCase()

        if (scripts[tag] === undefined) scripts[tag] = []
        scripts[tag].push({
          innerHTML: child.innerHTML
        })
      })
    }
    if (this.setting && this.setting.google_tag_body) {
      const parentThird = document.createElement('div')
      parentThird.innerHTML = this.setting.google_tag_body
      parentThird.childNodes.forEach(child => {
        let tag = child.tagName
        if (tag === undefined) return
        tag = tag.toLowerCase()

        if (scripts[tag] === undefined) scripts[tag] = []
        scripts[tag].push({
          innerHTML: child.innerHTML
        })
      })
    }
    scripts.title = this.$t('home.title')
    scripts.meta = [
      { hid: 'twitter:card', name: 'twitter:card', content: 'website' },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=3' }
    ]
    if (this.setting && this.setting.meta_title) {
      scripts.meta.push(
        { hid: 'title', name: 'title', content: this.setting.meta_title }
      )
      scripts.meta.push(
        { hid: 'twitter:title', name: 'twitter:title', content: this.setting.meta_title }
      )
      scripts.meta.push(
        { hid: 'og:title', property: 'og:title', content: this.setting.meta_title }
      )
    }
    if (this.setting && this.setting.meta_description) {
      scripts.meta.push(
        { hid: 'description', name: 'description', content: this.setting.meta_description }
      )
      scripts.meta.push(
        { hid: 'twitter:description', name: 'twitter:description', content: this.setting.meta_description }
      )
      scripts.meta.push(
        { hid: 'og:description', property: 'og:description', content: this.setting.meta_description }
      )
    }
    if (this.setting && this.setting.meta_image) {
      scripts.meta.push(
        { hid: 'twitter:image', name: 'twitter:image', content: this.setting.meta_image }
      )
      scripts.meta.push(
        { hid: 'og:image', property: 'og:image', content: this.setting.meta_image }
      )
    }
    if (this.setting && this.setting.meta_keywords) {
      scripts.meta.push(
        { hid: 'twitter:keywords', name: 'twitter:keywords', content: this.setting.meta_keywords }
      )
      scripts.meta.push(
        { hid: 'og:keywords', property: 'og:keywords', content: this.setting.meta_keywords }
      )
    }
    return scripts
  },
  computed: {
    ...mapState('authentication', {
      openModalLoginState: state => state.openModalLoginState
    }),
    ...mapState({
      setting: state => state.setting
    }),
    hideFooter() {
      return this.$store.state.hideFooter
    },
    switchChat() {
      return this.$store.state.chat.switchChat
    }
  },
  watch: {
    $route() {
      this.$store.commit(INDEX_SET_HIDE_FOOTER, false)
    },
    switchChat() {
      if (this.switchChat && !this.$auth.loggedIn) {
        this.showLoginBox()
      }
    }
  },
  async created() {
    const setting = await this.$store.dispatch(GET_SETTING)
    this.$store.commit(INDEX_SET_SETTING, setting.data)
  },
  async mounted() {
    const now = new Date()
    this.timeAccess = JSON.parse(window.localStorage.getItem('time-access')) ?? this.timeAccess
    if (this.timeAccess.expiry < now.getTime()) {
      window.localStorage.removeItem('time-access')
      this.timeAccess = {
        value: 0,
        expiry: 0
      }
    }
    if (this.timeAccess.value < 3 && !window.location.pathname.replace('/', '')) {
      this.popupPhoneContact = true
      if (this.timeAccess.value === 0) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        today.setHours(23)
        this.timeAccess.expiry = today.getTime()
      }
      this.timeAccess.value = this.timeAccess.value + 1
      window.localStorage.setItem('time-access', JSON.stringify(this.timeAccess))
    } else {
      this.popupPhoneContact = false
    }
    this.imagePhoneContact = this.listImagePhoneContact[this.ramdomImagePhoneContact]

    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    if (this.$device.isMobile) {
      const response = await this.$store.dispatch(CHAT_FETCH_ROOMS, {
        per_page: 1000,
        page: 1,
        shop_id: '',
        name: ''
      })
      if (response.status_code === 200 && response.data.data.length) {
        this.$store.commit(CHAT_SET_UN_READ_MESSAGE, response.data.data.map(item => item.unread_total_user).reduce((prev, curr) => prev + curr, 0) || '')
      }
    }
    setTimeout(() => {
      this.showPhoneAni = false
    }, 30000)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async showLoginBox() {
      await this.$store.commit(AUTH_OPEN_MODAL_LOGIN, !this.openModalLoginState)
    },

    async handleSubmitPhoneContact() {
      try {
        await this.$store.dispatch(PHONE_CONTACT, {
          'phone_number': this.phoneNumber
        })
        this.popupPhoneContact = false
      } catch (e) {
        console.log(e)
      }
    },

    async fetchDataCategory() {
      try {
        const category = await this.$store.dispatch(MASTER_GET_DATA, 'resources[categories]')
        this.categories = this.convertData(category.data.categories)
      } catch (e) {
        console.log(e)
      }
    },
    onResize(ev) {
      this.windowWidth = window.innerWidth
    },
    convertData(category) {
      const list = []
      category.forEach(cat => {
        if (cat.children.length > 0) {
          cat.children = this.convertData(cat.children)
        }
        list.push({
          id: cat.id,
          name: cat.name,
          link: '/category/' + textToSlug(removeVietnameseTones(cat.name)) + '.' + cat.id,
          image: cat.image_url,
          image_thumb_url: cat.image_thumb_url,
          children: cat.children
        })
      })
      return list
    }
  }
}
