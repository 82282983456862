
export default {
  name: 'FooterCommon',
  // eslint-disable-next-line vue/require-prop-types
  props: ['listMenu'],
  data() {
    return {
      listMenuLeft: [
        {
          key: 'introduction',
          title: this.$t('home.footer.introduction'),
          link: '/gioi-thieu',
          rel: 'nofollow'
        },
        {
          key: 'contact_customer',
          title: this.$t('home.footer.contact_customer'),
          link: '/ho-tro-khach-hang',
          rel: 'nofollow'
        },
        {
          key: 'privacy',
          title: this.$t('home.footer.privacy'),
          link: '/dieu-khoan-su-dung-chinh-sach-bao-mat',
          rel: 'nofollow'
        },
        {
          key: 'refund',
          title: this.$t('home.footer.refund'),
          link: '/tra-hang-hoan-tien',
          rel: 'nofollow'
        },
        {
          key: 'store',
          title: this.$t('home.footer.store'),
          link: '/ban-hang-doanh-nghiep',
          rel: 'nofollow'
        },
        {
          key: 'insurance',
          title: this.$t('home.footer.insurance'),
          link: '/chinh-sach-bao-hanh',
          rel: 'nofollow'
        },
        {
          key: 'contact',
          title: this.$t('home.footer.contact'),
          link: '/thong-tin-lien-he',
          rel: 'nofollow'
        }
      ]
    }
  }
}
