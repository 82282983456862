
import {
  AUTH_FORGOT_PASSWORD,
  AUTH_RE_SEND_OTP,
  AUTH_FORGOT_PASSWORD_VERIFY,
  INDEX_SET_ERROR,
  INDEX_SET_LOADING,
  INDEX_SET_SUCCESS,
  AUTH_RESET_PASSWORD
} from '../../store/store.const'
import { handleServerError } from '@/utils/validate'

export default {
  name: 'ForgotPasswordElement',
  data() {
    return {
      accountForm: {
        phone: '',
        name: '',
        password: '',
        password_confirm: '',
        token: '',
        errors: {}
      },
      lengthCode: 6,
      verifyCode: [],
      accountRules: {
        phone: [
          {
            required: true,
            message: this.$t('validation.required', { _field_: this.$t('modal_login.phone') }),
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: this.$t('validation.required', { _field_: this.$t('modal_login.phone') }),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('validation.required', { _field_: this.$t('modal_login.password') }),
            trigger: 'blur'
          }
        ],
        password_confirm: [
          {
            required: true,
            message: this.$t('validation.required', { _field_: this.$t('modal_login.password') }),
            trigger: 'blur'
          }
        ],
        remember: []
      },
      capsToolPasswordTip: false,
      loading: false,
      fullscreenLoading: false,
      step: 1,
      configTitle: {
        1: this.$t('modal_login.send_forgot_password'),
        2: this.$t('modal_login.title_confirm'),
        3: this.$t('modal_login.reset_password')
      },
      capsToolNewPasswordTip: false,
      capsToolReNewPasswordTip: false,
      passwordTypeReNew: 'password',
      passwordTypeNew: 'password',
      otpCode: [
        { key: 1, value: null },
        { key: 2, value: null },
        { key: 3, value: null },
        { key: 4, value: null },
        { key: 5, value: null },
        { key: 6, value: null }
      ],
      timerCount: 0,
      idCount: null
    }
  },
  computed: {
    disabledButtonStep1() {
      if (this.accountForm.phone.length < 10) {
        return true
      }
      return false
    },
    disabledButtonStep2() {
      if (this.accountForm.phone.length < 10 || this.verifyCode.length !== this.lengthCode) {
        return true
      }
      for (let i = 0; i < this.verifyCode.length; i++) {
        if (this.verifyCode[i] == null || !this.verifyCode[i].toString().trim()) {
          return true
        }
      }
      return false
    },
    disabledButtonStep3() {
      if (this.accountForm.phone.length < 10) {
        return true
      }
      return false
    }
  },
  watch: {
    step() {
      this.$emit('changeTitle', { state: 'forgot', value: this.configTitle[this.step] })
    },
    timerCount: {
      handler(value) {
        if (value === 60) {
          clearInterval()
        }
        if (value > 0) {
          this.idCount = setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.$refs.accountFormStep1 !== undefined) {
      this.resetValidate('accountFormStep1')
    }
  },
  methods: {
    resetValidate(ref) {
      this.$refs['accountFormStep' + this.step].fields.find((f) => f.prop === ref).clearValidate()
      this.accountForm.errors[ref] = ''
    },
    checkCapslock(e, attr) {
      const { key } = e
      this[attr] = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    sendOtp() {
      if (!this.disabledButtonStep1) {
        this.$refs.accountFormStep1.validate(async valid => {
          if (valid) {
            try {
              await this.$store.commit(INDEX_SET_LOADING, true)
              const data = await this.$store.dispatch(AUTH_FORGOT_PASSWORD, {
                phone_number: this.accountForm.phone,
                type: 2
              })
              if (data.status_code === 200) {
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: data.message
                })
                this.step = 2
                this.timerCount = 60
              } else {
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
              }
            } catch (err) {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
            }
            await this.$store.commit(INDEX_SET_LOADING, false)
          }
        })
      }
    },
    async reSendOtp() {
      try {
        await this.$store.commit(INDEX_SET_LOADING, true)
        const data = await this.$store.dispatch(AUTH_RE_SEND_OTP, { phone_number: this.accountForm.phone, type: 3 })
        if (data.status_code === 200) {
          await this.$store.commit(INDEX_SET_SUCCESS, {
            show: true,
            text: data.message
          })
          this.timerCount = 60
        } else {
          await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
        }
      } catch (err) {
        await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
      }
      await this.$store.commit(INDEX_SET_LOADING, false)
    },
    onlyNumber($event, index) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault()
      } else {
        this.$refs.optCode[index < 5 ? index + 1 : 5].focus()
      }
    },
    confirmOtp() {
      if (!this.disabledButtonStep2) {
        this.$refs.accountFormStep2.validate(async valid => {
          if (valid) {
            try {
              await this.$store.commit(INDEX_SET_LOADING, true)
              const data = await this.$store.dispatch(AUTH_FORGOT_PASSWORD_VERIFY, {
                phone_number: this.accountForm.phone,
                code_otp: this.otp()
              })
              if (data.status_code === 200) {
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: data.message
                })
                this.accountForm.token = data.data.token
                this.step = 3
              } else {
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
              }
            } catch (err) {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
            }
            await this.$store.commit(INDEX_SET_LOADING, false)
          }
        })
      }
    },
    backPrevInput($event, index) {
      if ((index > 0) && (this.otpCode[index].value === '')) {
        this.$refs.optCode[index - 1].focus()
        $event.preventDefault()
      }
    },
    resetPassword() {
      if (!this.disabledButtonStep3) {
        this.$refs.accountFormStep3.validate(async valid => {
          if (valid) {
            try {
              await this.$store.commit(INDEX_SET_LOADING, true)
              const data = await this.$store.dispatch(AUTH_RESET_PASSWORD,
                {
                  password: this.accountForm.newPassword,
                  password_confirmation: this.accountForm.reNewPassword,
                  token: this.accountForm.token
                }
              )
              if (data.status_code === 200) {
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: data.message
                })
                await this.$store.commit(INDEX_SET_LOADING, false)
                await this.$emit('change', 'login')
              } else {
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
              }
            } catch (err) {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
            }
            await this.$store.commit(INDEX_SET_LOADING, false)
          }
        })
      }
    },
    handleUpdate() {
      this.$refs.accountForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.fullscreenLoading = true
          this.$store.dispatch(`account/update`, this.accountForm)
            .then((response) => {
              this.$message({ message: response.message, type: 'success' })
              this.loading = false
              this.fullscreenLoading = false
            })
            .catch((data) => {
              if (data.code === 422) {
                this.accountForm.errors = handleServerError(this.accountForm.errors, data.data)
              }
              this.loading = false
              this.fullscreenLoading = false
            })
        } else {
          return false
        }
      })
    },
    async changeModal(state) {
      await this.$emit('change', state)
    },
    async changeStep(step) {
      if (this.step === 2) {
        clearInterval(this.idCount)
      }
      if (step === 0) {
        await this.changeModal('login')
      } else {
        this.step = step
      }
    },
    formatPhone(phone_number) {
      return '(+84)' + phone_number.slice(1, (phone_number.length))
    },
    showPwd(attr) {
      if (this[attr] === 'password') {
        this[attr] = ''
      } else {
        this[attr] = 'password'
      }
    },
    handlePaste(e, index) {
      const data = e.clipboardData.getData('text')
      let count = 0
      for (let i = index, j = 0; i <= this.lengthCode && j < data.length; i++, j++) {
        if (isNaN(data.charAt(j))) {
          --i
          continue
        }
        this.$refs['code-' + (Number(i)).toString()][0].$el.getElementsByTagName('input')[0].value = data.charAt(j)
        this.verifyCode[i - 1] = data.charAt(j)
        count = i - 1
        if (count < this.lengthCode) {
          const nextCode = this.$refs['code-' + (Number(count) + 1).toString()][0]
          nextCode.focus()
        }
      }
    },
    handleCode(index, e) {
      if (e.keyCode === 8 || e.keyCode === 46) {
        if (index > 1 && e.target.value === '') {
          this.$refs['code-' + (Number(index) - 1).toString()][0].focus()
        }
      } else {
        if (!e.target.value.trim()) {
          this.$refs['code-' + (Number(index)).toString()][0].$el.getElementsByTagName('input')[0].value = ''
          return
        }
        const codeBox = this.$refs['code-' + (Number(index)).toString()][0].$el.getElementsByTagName('input')[0]
        if (codeBox.value != null && codeBox.value && !isNaN(e.key)) {
          this.verifyCode[index - 1] = Number(e.key)
          codeBox.value = Number(e.key)
        }
        const [first, ...rest] = e.target.value

        this.$refs['code-' + (Number(index)).toString()][0].$el.getElementsByTagName('input')[0].value = e.target.value
        e.target.value = first ?? ''
        const lastInputBox = index === this.lengthCode
        const insertedContent = first !== undefined
        if (insertedContent && !lastInputBox) {
          const nextCode = this.$refs['code-' + (Number(index) + 1).toString()][0]
          nextCode.focus()
          nextCode.$el.value = rest.join('')
        }
      }
    },
    otp() {
      return this.verifyCode.join('').toString()
    }
  }
}
