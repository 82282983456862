
import { mapState } from 'vuex'
import { AUTH_OPEN_MODAL_LOGIN, CHAT_SET_SWITCH_CHAT, INDEX_SET_OPEN_SIDEBAR } from '../../store/store.const'

export default {
  name: 'FooterMobile',
  computed: {
    ...mapState('authentication', {
      openModalLoginState: state => state.openModalLoginState
    }),
    ...mapState('cart', {
      cartCount: state => state.count
    }),
    ...mapState('chat', {
      switchChat: state => state.switchChat,
      unreadMessage: state => state.unreadMessage
    })
  },
  methods: {
    showCart() {
      if (this.$auth.loggedIn) {
        this.$router.push('/cart')
      } else {
        this.$store.commit(AUTH_OPEN_MODAL_LOGIN, !this.openModalLoginState)
      }
    },

    setSwitchChat() {
      this.$store.commit(CHAT_SET_SWITCH_CHAT, !this.switchChat)
    },

    openSidebar() {
      if (this.$auth.loggedIn) {
        this.$store.commit(INDEX_SET_OPEN_SIDEBAR, this.$store.state.openSidebar + 1)
      } else {
        this.$store.commit(AUTH_OPEN_MODAL_LOGIN, !this.openModalLoginState)
      }
    }
  }
}
