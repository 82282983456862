import { handleApi } from '../utils/handleApi'
export const state = () => ({
})

export const mutations = {
}

export const actions = {
  getListCollection(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/collections?' + new URLSearchParams(params.filters).toString()), context)
    })
  },
  getCollectionDetail(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/collections/' + params.slug), context)
    })
  },
  getCollectionProduct(context, data) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get(`/collections/${data.id}/collection-product?` + new URLSearchParams(data.filters).toString()), context)
    })
  },
  getRelatedCollection(context, id) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get(`/collections/${id}/relate-collection`), context)
    })
  }
}
