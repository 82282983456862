
import vClickOutside from 'v-click-outside'
export default {
  name: 'ExpandContact',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    setting: {
      type: Object,
      default: () => {}
    },
    isShop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      supportExpand: false
    }
  }
}
