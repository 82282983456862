
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'ListMenu',
  components: { VueSlickCarousel },
  // eslint-disable-next-line vue/require-prop-types
  props: ['listMenu'],
  data() {
    return {
      childContent: '',
      slideSetting: {
        'dots': false,
        'focusOnSelect': false,
        'infinite': false,
        'initialSlide': 0,
        'speed': 500,
        'slidesToShow': 6,
        'slidesToScroll': 1,
        'swipeToSlide': true
      },
      slideSettingTab: {
        'dots': false,
        'focusOnSelect': false,
        'infinite': false,
        'initialSlide': 0,
        'speed': 500,
        'slidesToShow': 4,
        'slidesToScroll': 1,
        'swipeToSlide': true
      },
      showModal: false,
      showActive: null
    }
  },
  methods: {
    showMenu() {
      this.showModal = true
    },
    hideMenu() {
      this.showModal = false
      this.showActive = null
    },
    changeToLink(link) {
      this.$router.push(link)
    },
    getVal(val) {
      this.childContent = val.children
    },
    getResetChildContent(index) {
      this.childContent = this.listMenu[index].children[0].children
    }
  }
}
