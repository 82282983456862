import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d085a6e = () => interopDefault(import('../pages/ban-hang-doanh-nghiep.vue' /* webpackChunkName: "pages/ban-hang-doanh-nghiep" */))
const _5e740bba = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _1bb8e33e = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _bf4e1ffc = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _7e766c8a = () => interopDefault(import('../pages/chinh-sach-bao-hanh.vue' /* webpackChunkName: "pages/chinh-sach-bao-hanh" */))
const _c368b93c = () => interopDefault(import('../pages/collection/index.vue' /* webpackChunkName: "pages/collection/index" */))
const _202dfae5 = () => interopDefault(import('../pages/dieu-khoan-su-dung-chinh-sach-bao-mat.vue' /* webpackChunkName: "pages/dieu-khoan-su-dung-chinh-sach-bao-mat" */))
const _69bb6be7 = () => interopDefault(import('../pages/error_page.vue' /* webpackChunkName: "pages/error_page" */))
const _244708d6 = () => interopDefault(import('../pages/gioi-thieu.vue' /* webpackChunkName: "pages/gioi-thieu" */))
const _23a75d32 = () => interopDefault(import('../pages/ho-tro-khach-hang.vue' /* webpackChunkName: "pages/ho-tro-khach-hang" */))
const _0f32b61b = () => interopDefault(import('../pages/my_account.vue' /* webpackChunkName: "pages/my_account" */))
const _594a43a5 = () => interopDefault(import('../pages/not_found.vue' /* webpackChunkName: "pages/not_found" */))
const _2130f534 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _d22522ae = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _70165846 = () => interopDefault(import('../pages/thong-tin-lien-he.vue' /* webpackChunkName: "pages/thong-tin-lien-he" */))
const _5f47fae7 = () => interopDefault(import('../pages/tra-hang-hoan-tien.vue' /* webpackChunkName: "pages/tra-hang-hoan-tien" */))
const _188fb8b2 = () => interopDefault(import('../pages/blogs/search.vue' /* webpackChunkName: "pages/blogs/search" */))
const _6ff4ab06 = () => interopDefault(import('../pages/product/favorite.vue' /* webpackChunkName: "pages/product/favorite" */))
const _52496154 = () => interopDefault(import('../pages/product/old.vue' /* webpackChunkName: "pages/product/old" */))
const _787ebd26 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _61e14e4a = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _c2bb628c = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _bccb1178 = () => interopDefault(import('../pages/checkout/_code.vue' /* webpackChunkName: "pages/checkout/_code" */))
const _b461d36c = () => interopDefault(import('../pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _f94ddd2e = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _37eee826 = () => interopDefault(import('../pages/san-pham/_slug.vue' /* webpackChunkName: "pages/san-pham/_slug" */))
const _05b008ab = () => interopDefault(import('../pages/store/_slug.vue' /* webpackChunkName: "pages/store/_slug" */))
const _181a46e1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7a4d3924 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ban-hang-doanh-nghiep",
    component: _3d085a6e,
    name: "ban-hang-doanh-nghiep"
  }, {
    path: "/blogs",
    component: _5e740bba,
    name: "blogs"
  }, {
    path: "/cart",
    component: _1bb8e33e,
    name: "cart"
  }, {
    path: "/category",
    component: _bf4e1ffc,
    name: "category"
  }, {
    path: "/chinh-sach-bao-hanh",
    component: _7e766c8a,
    name: "chinh-sach-bao-hanh"
  }, {
    path: "/collection",
    component: _c368b93c,
    name: "collection"
  }, {
    path: "/dieu-khoan-su-dung-chinh-sach-bao-mat",
    component: _202dfae5,
    name: "dieu-khoan-su-dung-chinh-sach-bao-mat"
  }, {
    path: "/error_page",
    component: _69bb6be7,
    name: "error_page"
  }, {
    path: "/gioi-thieu",
    component: _244708d6,
    name: "gioi-thieu"
  }, {
    path: "/ho-tro-khach-hang",
    component: _23a75d32,
    name: "ho-tro-khach-hang"
  }, {
    path: "/my_account",
    component: _0f32b61b,
    name: "my_account"
  }, {
    path: "/not_found",
    component: _594a43a5,
    name: "not_found"
  }, {
    path: "/orders",
    component: _2130f534,
    name: "orders"
  }, {
    path: "/search",
    component: _d22522ae,
    name: "search"
  }, {
    path: "/thong-tin-lien-he",
    component: _70165846,
    name: "thong-tin-lien-he"
  }, {
    path: "/tra-hang-hoan-tien",
    component: _5f47fae7,
    name: "tra-hang-hoan-tien"
  }, {
    path: "/blogs/search",
    component: _188fb8b2,
    name: "blogs-search"
  }, {
    path: "/product/favorite",
    component: _6ff4ab06,
    name: "product-favorite"
  }, {
    path: "/product/old",
    component: _52496154,
    name: "product-old"
  }, {
    path: "/checkout/success/:id?",
    component: _787ebd26,
    name: "checkout-success-id"
  }, {
    path: "/blogs/:slug",
    component: _61e14e4a,
    name: "blogs-slug"
  }, {
    path: "/category/:slug",
    component: _c2bb628c,
    name: "category-slug"
  }, {
    path: "/checkout/:code?",
    component: _bccb1178,
    name: "checkout-code"
  }, {
    path: "/collection/:id",
    component: _b461d36c,
    name: "collection-id"
  }, {
    path: "/product/:id?",
    component: _f94ddd2e,
    name: "product-id"
  }, {
    path: "/san-pham/:slug?",
    component: _37eee826,
    name: "san-pham-slug"
  }, {
    path: "/store/:slug?",
    component: _05b008ab,
    name: "store-slug"
  }, {
    path: "/",
    component: _181a46e1,
    name: "index"
  }, {
    path: "/*",
    component: _7a4d3924,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
